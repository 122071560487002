/* eslint-disable */

<template>
  <div class="vx-col">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <vx-card class="mb-base">
        <vue-draggable-container ref="wordBank" id="wordBank" class="wordBank">
          <h5
            class="dragBlock font-light cursor-pointer"
            style="font-weight: 400 !important; font-size: 14px !important"
            v-for="item in containerAnswerItems"
            :key="item.id"
            :id="item.id"
          >
            {{ item.text }}
          </h5>
          <h5
            class="dragBlock font-light cursor-pointer"
            style="font-weight: 400 !important; font-size: 14px !important"
            v-for="item in vmAnswerItems"
            :key="item.id"
            :id="item.id"
          >
            {{ item.text }}
          </h5>
        </vue-draggable-container>

        <div v-if="showPlaceholder" class="placeholder">No elements in the container</div>
      </vx-card>

      <div class="flex gap-x-8 w-full">
        <div class="bg-dark main-border rounded-lg w-full overflow-hidden">
          <div class="bg-dark main-bottom-border text-xl flex justify-center p-2">Container Answer Box</div>

          <div class="p-4">
            <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="container"> </vue-draggable-container>
          </div>
        </div>

        <div class="bg-dark main-border rounded-lg w-full overflow-hidden">
          <div class="bg-dark main-bottom-border text-xl flex justify-center p-2">Virtual Machine Answer Box</div>

          <div class="p-4">
            <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="vm"> </vue-draggable-container>
          </div>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      containerAnswerItems: [
        { id: 1, text: 'Takes less time to start up', answer: 1 },
        { id: 2, text: 'Shares an operating system with the host', answer: 1 },
        { id: 3, text: 'Uses a platform instead of a hypervisor', answer: 1 },
        { id: 4, text: 'Can scale quickly and efficiently', answer: 1 },
        { id: 5, text: 'Does not require a lot of resources', answer: 1 },
        { id: 6, text: 'Can be moved off a device with ease (portable)', answer: 1 },
        { id: 7, text: 'Limited to Role-Based Access Control', answer: 1 },
        { id: 8, text: 'Kubernetes', answer: 1 },
        { id: 9, text: 'Docker', answer: 1 },
      ],

      vmAnswerItems: [
        { id: 10, text: 'Takes more time to start up', answer: 2 },
        { id: 11, text: 'Has its own operating system', answer: 2 },
        { id: 12, text: 'Uses Hypervisors to run multiple operating systems', answer: 2 },
        { id: 13, text: 'Can scale but takes longer due to having an OS', answer: 2 },
        { id: 14, text: 'Requires a lot of resources', answer: 2 },
        { id: 15, text: 'More difficult to achieve portability', answer: 2 },
        { id: 16, text: 'VMware', answer: 2 },
        { id: 17, text: 'Capable of using different Access Control Types', answer: 2 },
      ],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-ports-practice currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 8 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;
      const containerElement = document.getElementById('container');
      const vmElement = document.getElementById('vm');

      if (containerElement) {
        const childElementsArray = Array.from(containerElement.children);

        childElementsArray.forEach((childElement) => {
          const matchingAnswerItem = this.containerAnswerItems.find((answerItem) => {
            return answerItem.id === parseInt(childElement.id, 10);
          });

          if (matchingAnswerItem) {
            const answerBlockElement = document.getElementById(matchingAnswerItem.id);
            answerBlockElement.classList.remove('dragBlock');
            answerBlockElement.classList.add('correctDragBlock');
            totalScore++;
          }
        });

        if (totalScore === this.dataLocal.simulation.totalScore) {
          return this.$emit('simulation_full_correct', totalScore);
        }

        this.$emit('simulation_getting_there', totalScore);
      }

      if (vmElement) {
        const childElementsArray = Array.from(vmElement.children);

        childElementsArray.forEach((childElement) => {
          const matchingAnswerItem = this.vmAnswerItems.find((answerItem) => {
            return answerItem.id === parseInt(childElement.id, 10);
          });

          if (matchingAnswerItem) {
            const answerBlockElement = document.getElementById(matchingAnswerItem.id);
            answerBlockElement.classList.remove('dragBlock');
            answerBlockElement.classList.add('correctDragBlock');
            totalScore++;
          }
        });

        if (totalScore === this.dataLocal.simulation.totalScore) {
          return this.$emit('simulation_full_correct', totalScore);
        }

        this.$emit('simulation_getting_there', totalScore);
      }
    },
  },
  components: {
    Prism,
    vSelect,
    MovablePopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.wordBank {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.6rem;
  min-height: unset;
}
.answerStandard {
  padding: 0.4rem !important;
  gap: 0.4rem;
}

.correctDragBlock {
  border: 1px solid #31b952 !important;
  background-color: #254e2f !important;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 0.6rem;
  padding: 10px;
  cursor: not-allowed !important;
}

.incorrectDragBlock {
  border: 1px solid #c93738 !important;
  background-color: #532727 !important;
}
</style>
